import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import '../Detail/Detail.scss';
import {
  Button,
  OutlineButton,
  MovieList,
  ArtistList,
  MediaModal,
  MediaModal2,
  TrailerModal,
  LoadingSpinner,
} from '../../components';
import copyIcon from '../../assets/copy-icon.svg';
import eyeIcon from '../../assets/eye-icon.svg';
import eyeFilledIcon from '../../assets/eye-filled-icon.svg';
import backgroundIcon from '../../assets/background-icon.svg';
import { Link } from 'react-router-dom';
import {
  useGetMovieQuery,
  useGetMovieUserQuery,
  useSaveMovieUserMutation,
} from '../../app/services/movieServerApi';
import errorIcon from '../../assets/error.svg';
import {
  getOverviewFontSize,
  getOriginalTitleFontSize,
  getTitleFontSize,
  getMainAttribute,
  getLanguagesWithAudio,
  getLanguagesWithSubtitle,
} from '../../shared/utils';
import { isSafari } from 'react-device-detect';

export const MovieDetail = () => {
  const { category, id } = useParams();
  const {
    data: movieItem = {},
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetMovieQuery(id);

  const {
    data: movieUserItem = {},
    // isLoading,
    // // isFetching,
    isError: isErrorMovieUserItem,
    // error,
  } = useGetMovieUserQuery(id);

  const background = movieItem.background
    ? movieItem.background
    : movieItem.cover
      ? movieItem.cover
      : movieItem.image;
  const renderSimilarItems = () => {
    return (
      <MovieList category={category} mode="similar" ulid={movieItem.ulid} />
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [category, id]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(movieItem?.filePath);
    alert('Movie link copied to clipboard');
  };

  const [openMovieModal, setOpenMovieModal] = useState(false);
  const toggleMovieModal = () => setOpenMovieModal(!openMovieModal);

  const [openTrailerModal, setOpenTrailerModal] = useState(false);
  const toggleTrailerModal = () => setOpenTrailerModal(!openTrailerModal);

  const [saveMovieUser] = useSaveMovieUserMutation();

  const markAsWatched = (watched: boolean) => {
    const data = { id: id, watched: watched };
    saveMovieUser(data);
  };

  const [loadedBackground, setLoadedBackground] = useState(false);
  const handleLoadBackground = () => {
    setLoadedBackground(true);
  };
  const [loadedCover, setLoadedCover] = useState(false);
  const handleLoadCover = () => {
    setLoadedCover(true);
  };

  return (
    <div>
      {isLoading || isFetching ? (
        <div className="media-slide-no-item-single">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="media-slide-no-item-single">
          <img src={errorIcon} alt="error-icon" />
          <p>
            {error?.status === 404
              ? 'Page Not Found'
              : error?.error
                ? error?.error
                : 'An error ocurred'}
          </p>
          {error?.status === 404 && (
            <Link className="media-a" to="/">
              <Button>Go Back to the Home Page</Button>
            </Link>
          )}
        </div>
      ) : (
        <>
          <img
            src={background}
            alt=""
            onLoad={handleLoadBackground}
            style={{ display: 'none' }}
          />
          <img
            src={movieItem.cover}
            alt=""
            onLoad={handleLoadCover}
            style={{ display: 'none' }}
          />
          <div
            className="banner"
            style={{
              backgroundImage: loadedBackground
                ? `url(${background})`
                : undefined,
              // filter: loaded ? "blur(0em)" : "blur(0.2em)",
              // transition: "filter 0.5s",
            }}
          >
            <div className="movie-content media-container">
              <div className="movie-content__info">
                <h1
                  className="title"
                  style={{
                    fontSize: getTitleFontSize(
                      movieItem.title || movieItem.name,
                    ),
                  }}
                >
                  {movieItem.title || movieItem.name}
                </h1>
                {movieItem.originalTitle ? (
                  <h3
                    className="original-title"
                    style={{
                      fontSize: getOriginalTitleFontSize(
                        movieItem.originalTitle,
                      ),
                    }}
                  >
                    Original Title: {movieItem.originalTitle}
                  </h3>
                ) : null}
                <div className="genres">
                  <Link
                    className="media-a"
                    to={'/movies/?year=' + movieItem.releaseDate}
                  >
                    <span className="badge genres__item">
                      {movieItem.releaseDate}
                    </span>
                  </Link>
                  <span className="badge genres__item">
                    {movieItem.runtime}
                  </span>
                  <Link
                    className="media-a"
                    to={
                      '/movies/?genre=' +
                      getMainAttribute(movieItem.movieGenres)?.genre?.label
                    }
                  >
                    <span className="badge genres__item">
                      {getMainAttribute(movieItem.movieGenres)?.genre?.label}
                    </span>
                  </Link>
                  <Link
                    className="media-a"
                    to={'/movies/?rating=' + movieItem.rating.label}
                  >
                    <span className="badge genres__item">
                      {movieItem.rating.label}
                    </span>
                  </Link>
                  <Link
                    className="media-a"
                    to={'/movies/?quality=' + movieItem.quality.label}
                  >
                    <span className="badge genres__item">
                      {movieItem.quality.label}
                    </span>
                  </Link>
                  <Link
                    className="media-a"
                    to={
                      '/movies/?language=' +
                      getMainAttribute(movieItem.movieLanguages)?.language
                        ?.label
                    }
                  >
                    <span className="badge genres__item">
                      {
                        getMainAttribute(movieItem.movieLanguages)?.language
                          ?.label
                      }
                    </span>
                  </Link>
                  <Link
                    className="media-a"
                    to={
                      '/movies/?country=' +
                      getMainAttribute(movieItem.movieCountries)?.country
                        ?.label
                    }
                  >
                    <span className="badge genres__item">
                      {
                        getMainAttribute(movieItem.movieCountries)?.country
                          ?.label
                      }
                    </span>
                  </Link>
                </div>
                <p
                  className="overview"
                  style={{
                    fontSize: getOverviewFontSize(movieItem.description),
                  }}
                >
                  {movieItem.description}
                </p>

                <div className="btns">
                  <Button onClick={toggleMovieModal}>Watch now</Button>
                  <OutlineButton onClick={toggleTrailerModal}>
                    Watch trailer
                  </OutlineButton>
                </div>
              </div>

              <div className="movie-content__poster">
                {loadedCover && (
                  <a
                    href={movieItem.coverOriginal}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="movie-content__poster__img_movie"
                      src={movieItem.cover}
                      alt={movieItem.title || movieItem.name}
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="movie-content-description">
            {movieItem.originalTitle ? (
              <h3
                className="original-title"
                style={{
                  fontSize: getOriginalTitleFontSize(movieItem.originalTitle),
                }}
              >
                Original Title: {movieItem.originalTitle}
              </h3>
            ) : null}
            <p
              className="overview"
              style={{
                fontSize: '1rem',
              }}
            >
              {movieItem.description}
            </p>
          </div>
          {(movieItem.movieDirectors.length ||
            movieItem.movieActors.length) && (
            <div className="movie-content-details">
              {movieItem.movieDirectors.length > 0 && (
                <div className="movie-content-details__info">
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Directors'}</h2>
                  </div>
                  <ArtistList
                    artists={movieItem.movieDirectors}
                    category="director"
                  />
                </div>
              )}
              {movieItem.movieActors.length > 0 && (
                <div className="movie-content-details__info">
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Cast'}</h2>
                  </div>
                  <ArtistList
                    artists={movieItem.movieActors}
                    category="actor"
                  />
                </div>
              )}
            </div>
          )}
          {(movieItem.movieProducers.length > 0 ||
            movieItem.movieScreenwriters.length > 0) && (
            <div className="movie-content-details">
              {movieItem.movieProducers.length > 0 && (
                <div className="movie-content-details__info">
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Producers'}</h2>
                  </div>
                  <ArtistList
                    artists={movieItem.movieProducers}
                    category="producer"
                  />
                </div>
              )}
              {movieItem.movieScreenwriters.length > 0 && (
                <div className="movie-content-details__info">
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Screenwriters'}</h2>
                  </div>
                  <ArtistList
                    artists={movieItem.movieScreenwriters}
                    category="screenwriter"
                  />
                </div>
              )}
            </div>
          )}
          <div className="movie-content-details">
            <div className="movie-content-details__info">
              {movieItem.movieGenres.length > 0 && (
                <Fragment>
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Genres'}</h2>
                  </div>
                  <div className="genres">
                    {movieItem.movieGenres.map(
                      (movieGenre: any, i: number) => (
                        <Link
                          className="media-a"
                          to={'/movies/?genre=' + movieGenre.genre.label}
                          key={i}
                        >
                          <span className="badge genres__item">
                            {movieGenre.genre.label}
                          </span>
                        </Link>
                      ),
                    )}
                  </div>
                </Fragment>
              )}
              {getLanguagesWithAudio(movieItem.movieLanguages).length > 0 && (
                <Fragment>
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Languages'}</h2>
                  </div>
                  <div className="genres">
                    {getLanguagesWithAudio(movieItem.movieLanguages).map(
                      (movieLanguage: any, i: number) => (
                        <Link
                          className="media-a"
                          to={
                            '/movies/?language=' + movieLanguage.language.label
                          }
                          key={i}
                        >
                          <span className="badge genres__item">
                            {movieLanguage.language.label}
                          </span>
                        </Link>
                      ),
                    )}
                  </div>
                </Fragment>
              )}

              {getLanguagesWithSubtitle(movieItem.movieLanguages).length >
                0 && (
                <Fragment>
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Subtitles'}</h2>
                  </div>
                  <div className="genres">
                    {getLanguagesWithSubtitle(movieItem.movieLanguages).map(
                      (movieLanguage: any, i: number) => (
                        <Link
                          className="media-a"
                          to={
                            '/movies/?subtitle=' + movieLanguage.language.label
                          }
                          key={i}
                        >
                          <span className="badge genres__item">
                            {movieLanguage.language.label}
                          </span>
                        </Link>
                      ),
                    )}
                  </div>
                </Fragment>
              )}

              {movieItem.movieCountries.length > 0 && (
                <Fragment>
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Countries'}</h2>
                  </div>
                  <div className="genres">
                    {movieItem.movieCountries.map(
                      (movieCountry: any, i: number) => (
                        <Link
                          className="media-a"
                          to={'/movies/?country=' + movieCountry.country.label}
                          key={i}
                        >
                          <span className="badge genres__item">
                            {movieCountry.country.label}
                          </span>
                        </Link>
                      ),
                    )}
                  </div>
                </Fragment>
              )}
            </div>

            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Additional Information'}</h2>
              </div>

              <div className="genres">
                {movieItem?.studio && (
                  <Link
                    className="media-a"
                    to={'/movies/?studio=' + movieItem.studio.label}
                  >
                    <span className="badge genres__item">
                      Studio: {movieItem.studio.label}
                    </span>
                  </Link>
                )}
                <span className="badge genres__item">
                  IMDB Rating: {movieItem.imdbRating}
                </span>
              </div>

              {movieItem?.copyright && (
                <div className="genres">
                  <p className="overview">Copyright: {movieItem.copyright}</p>
                </div>
              )}

              <div className="genres">
                <OutlineButton
                  onClick={() => copyToClipboard()}
                  className="secondary-button"
                >
                  <img src={copyIcon} width="25" height="25" alt="" />
                </OutlineButton>
                <OutlineButton
                  onClick={() =>
                    window.open(movieItem.backgroundOriginal, '_blank')
                  }
                  className="secondary-button"
                >
                  <img src={backgroundIcon} width="25" height="25" alt="" />
                </OutlineButton>

                <OutlineButton
                  onClick={() =>
                    markAsWatched(
                      !isErrorMovieUserItem && movieUserItem?.watched
                        ? false
                        : true,
                    )
                  }
                  className="secondary-button"
                >
                  <img
                    src={
                      !isErrorMovieUserItem && movieUserItem?.watched
                        ? eyeFilledIcon
                        : eyeIcon
                    }
                    width="25"
                    height="25"
                    alt=""
                  />
                </OutlineButton>
              </div>

              {movieItem.tags.length > 0 && (
                <Fragment>
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Tags'}</h2>
                  </div>
                  <div className="genres">
                    {movieItem.tags.map((tag: any, i: number) => (
                      <Link
                        className="media-a"
                        to={'/movies/?tag=' + tag.label}
                        key={i}
                      >
                        <span key={i} className="badge genres__item">
                          {tag.label}
                        </span>
                      </Link>
                    ))}
                  </div>
                </Fragment>
              )}
              {movieItem.collections.length > 0 && (
                <Fragment>
                  <div className="media-section__header">
                    <h2 className="subtitle">{'Collections'}</h2>
                  </div>
                  <div className="genres">
                    {movieItem.collections.map(
                      (collection: any, i: number) => (
                        <Link
                          className="media-a"
                          to={'/movies/?collection=' + collection.label}
                          key={i}
                        >
                          <span key={i} className="badge genres__item">
                            {collection.label}
                          </span>
                        </Link>
                      ),
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          </div>

          <div className="detail-container">
            <div className="media-section mt-3">
              <div className="media-section__header">
                <h2 className="subtitle">Similar</h2>
              </div>
              {renderSimilarItems()}
            </div>
          </div>

          <TrailerModal
            isOpen={openTrailerModal}
            toggle={toggleTrailerModal}
            item={movieItem}
          />
          {getLanguagesWithAudio(movieItem.movieLanguages).length > 1 &&
          isSafari ? (
            <MediaModal
              isOpen={openMovieModal}
              toggle={toggleMovieModal}
              item={movieItem}
              userItem={movieUserItem}
              category={'movies'}
            />
          ) : (
            <MediaModal2
              isOpen={openMovieModal}
              toggle={toggleMovieModal}
              item={movieItem}
              userItem={movieUserItem}
              category={'movies'}
            />
          )}
        </>
      )}
    </div>
  );
};
