import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useEffect, useRef, useState } from 'react';
import { useSaveMovieUserMutation } from '../../app/services/movieServerApi';
import { useParams } from 'react-router-dom';
import { useSaveEpisodeUserMutation } from '../../app/services/tvShowServerApi';
import { Button, OutlineButton } from '../Button';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import './MediaModal2.scss';
/* eslint-disable import/no-unresolved */
import {
  MediaPlayer,
  MediaProvider,
  Track,
  Poster,
  MediaPlayerInstance,
} from '@vidstack/react';
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from '@vidstack/react/player/layouts/default';

export const MediaModal2 = (props: any) => {
  const item = props.item;
  const category = props.category
  const mediaURL = item.filePath;
  const playerRef = useRef<MediaPlayerInstance>(null);
  const [saveMovieUser] = useSaveMovieUserMutation();
  const [saveEpisodeUser] = useSaveEpisodeUserMutation();
  const { id } = useParams();
  const [isReady, setIsReady] = useState(false);
  const [resumePlayback, setResumePlayback] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);

  useEffect(() => {
    if (playerRef.current && !props.isOpen) {
      const currentTime = playerRef.current.currentTime;
      const duration = playerRef.current.duration;
      if (duration && currentTime < duration) {
        const data = { id: id, seconds_watched: currentTime };
        if (category === 'movies') {
          saveMovieUser(data);
        } else if (category === 'tv-shows') {
          saveEpisodeUser(data);
        }
      }
    } else if (props.isOpen) {
      setShowPlayer(
        props?.userItem?.secondsWatched && props.userItem.secondsWatched > 0
          ? false
          : true,
      );
      setIsReady(
        props.userItem.secondsWatched && props.userItem.secondsWatched > 0
          ? false
          : true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const handleEnded = () => {
    const data = { id: id, watched: true };
    if (category === 'movies') {
      saveMovieUser(data);
    } else if (category === 'tv-shows') {
      saveEpisodeUser(data);
    }
  };

  const handleProgress = () => {
    const playing = playerRef.current?.state.playing;
    if (playing) {
      const playedSeconds = playerRef.current!.currentTime;
      const duration = playerRef.current!.duration;
      if (duration && playedSeconds < duration) {
        const data = { id: id, seconds_watched: playedSeconds };
        if (category === 'movies') {
          saveMovieUser(data);
        } else if (category === 'tv-shows') {
          saveEpisodeUser(data);
        }
      }
    }
    // We only want to update time slider if we are not currently seeking
  };

  const handleOnPause = () => {
    const playedSeconds = playerRef.current!.currentTime;
    const duration = playerRef.current!.duration;
    if (duration && Math.ceil(playedSeconds) < Math.ceil(duration)) {
      const data = { id: id, seconds_watched: playedSeconds };
      if (category === 'movies') {
        saveMovieUser(data);
      } else if (category === 'tv-shows') {
        saveEpisodeUser(data);
      }
    }
  };

  const handleResume = (resume: boolean) => {
    if (resume) {
      setResumePlayback(true);
    } else {
      setResumePlayback(false);
    }
    setShowPlayer(true);
  };

  const handleOnReady = () => {
    if (!isReady && playerRef.current && props?.userItem?.secondsWatched) {
      if (resumePlayback) {
        playerRef.current.remoteControl.seek(props.userItem.secondsWatched);
      }
      setIsReady(true);
    }
  };

  // const textTracks = [
  //   // {
  //   //   "src": "https://files.vidstack.io/sprite-fight/chapters.vtt",
  //   //   "language": "en-US",
  //   //   "kind": "chapters",
  //   //   "type": "vtt",
  //   //   "default": true
  //   // }
  // ]

  const languagesMapper = {
    English: 'en-US',
    Spanish: 'es-ES',
  };
  type languageKeyType = keyof typeof languagesMapper;

  const textTracks =
    category === 'movies'
      ? item?.movieLanguages?.map((movieLanguage: any) => ({
          src: movieLanguage?.subtitleFileVtt,
          label: movieLanguage?.language.label,
          language:
            languagesMapper[movieLanguage?.language.label as languageKeyType],
          kind: 'subtitles',
          type: 'vtt',
        }))
      : item?.episodeLanguages?.map((episodeLanguage: any) => ({
          src: episodeLanguage?.subtitleFileVtt,
          label: episodeLanguage?.language.label,
          language:
            languagesMapper[
              episodeLanguage?.language.label as languageKeyType
            ],
          kind: 'subtitles',
          type: 'vtt',
        }));

  const MINUTE_MS = 300000;
  useEffect(() => {
    const interval = setInterval(() => {
      handleProgress();
    }, MINUTE_MS);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        centered={true}
        className="modal-dialog modal-xl"
      >
        <div style={{ backgroundColor: '#0f0f0f', border: 'none' }}>
          <ModalHeader style={{ backgroundColor: '#0f0f0f', border: 'none' }}>
            <div
              className="media-modal__content__close"
              role="button"
              tabIndex={0}
              onKeyDown={props.toggle}
              onClick={props.toggle}
            >
              <i className="bi bi-x"></i>
            </div>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: '#0f0f0f' }}>
            {!showPlayer ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <Button onClick={() => handleResume(true)}>Resume</Button>
                <OutlineButton onClick={() => handleResume(false)}>
                  Start from the beginning
                </OutlineButton>
              </div>
            ) : (
              <MediaPlayer
                ref={playerRef}
                title={item.title}
                src={mediaURL}
                poster={!resumePlayback ? item.background ?? item.cover : null}
                onCanPlay={handleOnReady}
                // onProgress={handleProgress}
                onPause={handleOnPause}
                // progressInterval={300000}
                onEnded={handleEnded}
                // width="100%"
                // height="100%"
                crossOrigin
              >
                <MediaProvider>
                  <Poster className="vds-poster" />
                  {textTracks.map((track: any) => (
                    <Track {...track} key={track.src} />
                  ))}
                  {category === 'movies' && (
                    <Track
                      src={item.chaptersFileVtt}
                      kind="chapters"
                      label="English"
                      lang="en-US"
                      type="vtt"
                      default={true}
                    />
                  )}
                </MediaProvider>
                <DefaultVideoLayout
                  icons={defaultLayoutIcons}
                  thumbnails={item.thumbnailsFile}
                />
              </MediaPlayer>
            )}
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};
