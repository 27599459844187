import { useEffect, useState } from 'react';
import '../MovieCatalog/MediaGrid.scss';
import { TVShowCard, LoadingSpinner, OutlineButton } from '../../components';
import { MediaSearch } from '../MovieCatalog/MediaSearch';
import { useGetTVShowsQuery } from '../../app/services/tvShowServerApi';
import errorIcon from '../../assets/error.svg';
import noDataIcon from '../../assets/nodata.svg';
import { useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../interfaces/forms';
import { IDefaultSearchParams } from '../../interfaces/components';

export const TVShowCatalog = (props: any) => {
  const changePage = () => {
    const new_obj = {
      ...currentSearchParams,
      page: currentSearchParams.page + 1,
    };
    setCurrentSearchParams(new_obj);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const getDefaultSearchParams = () => {
    const defaultSearchParams: IDefaultSearchParams = {
      page: 1,
      mode: 'list',
    };
    const keyword = searchParams.get('keyword') || '';
    const tag = searchParams.get('tag') || '';
    const collection = searchParams.get('collection') || '';
    const network = searchParams.get('network') || '';

    const genre = searchParams.get('genre') || '';
    const rating = searchParams.get('rating') || '';
    const country = searchParams.get('country') || '';

    const quality = searchParams.get('quality') || '';
    const language = searchParams.get('language') || '';
    const subtitle = searchParams.get('subtitle') || '';

    if (keyword) {
      defaultSearchParams['keyword'] = keyword;
    }
    if (tag) {
      defaultSearchParams['tag'] = tag;
    }
    if (collection) {
      defaultSearchParams['collection'] = collection;
    }
    if (network) {
      defaultSearchParams['network'] = network;
    }

    if (genre) {
      defaultSearchParams['genre'] = genre;
    }
    if (rating) {
      defaultSearchParams['rating'] = rating;
    }
    if (country) {
      defaultSearchParams['country'] = country;
    }

    if (quality) {
      defaultSearchParams['quality'] = quality;
    }
    if (language) {
      defaultSearchParams['language'] = language;
    }
    if (subtitle) {
      defaultSearchParams['subtitle'] = subtitle;
    }
    if (props.selectedArtistId) {
      defaultSearchParams['selectedArtistId'] = props.selectedArtistId;
    }
    return defaultSearchParams;
  };

  const [currentSearchParams, setCurrentSearchParams] = useState(
    getDefaultSearchParams(),
  );

  useEffect(() => {
    if (searchParams.size === 0) {
      setCurrentSearchParams(getDefaultSearchParams());
    }
    // eslint-disable-next-line
  }, [searchParams]);

  const {
    data = {},
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetTVShowsQuery(currentSearchParams);

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  const renderLoadMoreButton = () => {
    if (data.currentPage < data.totalPages) {
      return isFetching && currentSearchParams.page !== 1 ? (
        <div className="list-no-item-single">
          <LoadingSpinner />
        </div>
      ) : !isFetching ? (
        <div className="media-grid__loadmore">
          <OutlineButton className="small" onClick={() => changePage()}>
            Load more
          </OutlineButton>
        </div>
      ) : null;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (data?.results?.length && isError && currentSearchParams.page !== 1) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
    }
    // eslint-disable-next-line
  }, [isFetching]);

  return (
    <>
      {!props.hideSearchBar && (
        <div className="section mb-3">
          <MediaSearch
            setCurrentSearchParamsFunction={setCurrentSearchParams}
            setParamsFunction={setSearchParams}
            currentSearchParams={currentSearchParams}
          />
        </div>
      )}

      {isLoading || (isFetching && currentSearchParams.page === 1) ? (
        <div className="list-no-item-single">
          <LoadingSpinner />
        </div>
      ) : isError && !data?.results ? (
        <div className="list-no-item-single">
          <img src={errorIcon} alt="error-icon" />
          <p>{error?.error}</p>
        </div>
      ) : data.results.length === 0 ? (
        <div className="list-no-item-single">
          <img src={noDataIcon} alt="no-item-icon" />
          <p>{'No Items'}</p>
        </div>
      ) : (
        <div className="media-grid">
          {data.results.map((item: any, i: number) => (
            <TVShowCard
              category={'tv-shows'}
              item={item}
              key={i}
              selectedArtistId={props.selectedArtistId}
            />
          ))}
        </div>
      )}
      {renderLoadMoreButton()}
    </>
  );
};
