import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import '../Detail/Detail.scss';
import {
  Button,
  LoadingSpinner,
  MovieCatalog,
  TVShowCatalog,
  AutographCatalog,
} from '../../components';
import { Link } from 'react-router-dom';
import { useGetArtistQuery } from '../../app/services/artistServerApi';
import errorIcon from '../../assets/error.svg';
import {
  getOriginalTitleFontSize,
  getTitleFontSize,
} from '../../shared/utils';

export const ArtistDetail = () => {
  const { category, id } = useParams();
  const {
    data: artistItem = {},
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetArtistQuery(id);

  const background = artistItem.photo;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [category, id]);

  const [loadedBackground, setLoadedBackground] = useState(false);
  const handleLoadBackground = () => {
    setLoadedBackground(true);
  };
  const [loadedCover, setLoadedCover] = useState(false);
  const handleLoadCover = () => {
    setLoadedCover(true);
  };

  return (
    <div>
      {isLoading || isFetching ? (
        <div className="media-slide-no-item-single">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="media-slide-no-item-single">
          <img src={errorIcon} alt="error-icon" />
          <p>
            {error?.status === 404
              ? 'Page Not Found'
              : error?.error
                ? error?.error
                : 'An error ocurred'}
          </p>
          {error?.status === 404 && (
            <Link className="media-a" to="/">
              <Button>Go Back to the Home Page</Button>
            </Link>
          )}
        </div>
      ) : (
        <>
          <img
            src={background}
            alt=""
            onLoad={handleLoadBackground}
            style={{ display: 'none' }}
          />
          <img
            src={artistItem.photo}
            alt=""
            onLoad={handleLoadCover}
            style={{ display: 'none' }}
          />
          <div
            className="banner"
            style={{
              backgroundImage: loadedBackground
                ? `url(${background})`
                : undefined,
            }}
          >
            <div className="movie-content media-container">
              <div className="movie-content__info">
                <h1
                  className="title"
                  style={{
                    fontSize: getTitleFontSize(artistItem.name),
                  }}
                >
                  {artistItem.name}
                </h1>
                {artistItem.nickname ? (
                  <h3
                    className="original-title"
                    style={{
                      fontSize: getOriginalTitleFontSize(artistItem.nickname),
                    }}
                  >
                    Nickname: {artistItem.nickname}
                  </h3>
                ) : null}
              </div>

              <div className="movie-content__poster">
                {loadedCover && (
                  <a
                    href={artistItem.photoOriginal}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="movie-content__poster__img_movie"
                      src={artistItem.photo}
                      alt={artistItem.name}
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="movie-content-description">
            {artistItem.nickname ? (
              <h3
                className="original-title"
                style={{
                  fontSize: getOriginalTitleFontSize(artistItem.nickname),
                }}
              >
                Nickname: {artistItem.nickname}
              </h3>
            ) : null}
          </div>

          <div className="container">
            <div className="media-section media-mb-3">
              <div className="media-section__header media-mb-1">
                <h2 className="media-h2">Movies</h2>
              </div>
              <MovieCatalog
                selectedArtistId={artistItem.ulid}
                hideSearchBar={true}
              />

              <div className="media-section__header media-mb-1">
                <h2 className="media-h2">TV Shows</h2>
              </div>
              <TVShowCatalog
                selectedArtistId={artistItem.ulid}
                hideSearchBar={true}
              />

              <div className="media-section__header media-mb-1">
                <h2 className="media-h2">Autograph</h2>
              </div>
              <AutographCatalog
                selectedArtistId={artistItem.ulid}
                hideSearchBar={true}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
