import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Button, PageHeader } from '../../components';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { useGetTokensMutation } from '../../app/services/authenticationServerApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { loginUser } from '../../app/slices/authenticationSlice';
import { useAppDispatch } from '../../app/hooks';
import Swal from 'sweetalert2';
import './Login.scss';
import { ILoginFormError, ILoginFormInput } from '../../interfaces/forms';

export const Login = () => {
  const userAccessToken = useSelector(
    (state: RootState) => state.authentication.userAccessToken,
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginFormInput>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [login, { isLoading, isError, isSuccess, error }] =
    useGetTokensMutation();

  const onSubmit: SubmitHandler<ILoginFormInput> = (data) =>
    login(data).then(function (result: any) {
      if (result && result.data) {
        dispatch(loginUser(result.data));
      } else if (result && result.error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          confirmButtonColor: 'green',
          footer: isLoginError(result.error)
            ? (result.error as ILoginFormError).data?.detail ||
              (result.error as ILoginFormError).error
            : 'An error ocurred',
        });
      }
    });

  useEffect(() => {
    if (userAccessToken) {
      navigate('/');
    }
  }, [navigate, userAccessToken]);

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  {
    isSuccess &&
      Swal.fire({
        title: 'Logged in succesfully',
        text: 'Redirecting to home page',
        icon: 'success',
        timer: 1000,
        showConfirmButton: false,
      });
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.detail ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  return (
    <>
      <PageHeader title={'Login'} />
      <div className="form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="userEmail">Email</Label>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id="userEmail"
                  placeholder="Enter your email"
                  type="email"
                  invalid={!!errors.email}
                  {...field}
                />
              )}
            />
            <FormFeedback>The email is required</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="userPassword">Password</Label>
            <Controller
              name="password"
              control={control}
              rules={{ required: 'Password is required' }}
              render={({ field }) => (
                <Input
                  id="userPassword"
                  placeholder="Enter your password"
                  type="password"
                  invalid={!!errors.password}
                  {...field}
                />
              )}
            />
            <FormFeedback>The password is required</FormFeedback>
          </FormGroup>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit">Login</Button>
          </div>
        </Form>
      </div>
    </>
  );
};
